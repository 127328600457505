<template>
	<section class="content redesign">
		<div class="modal" tabindex="-1" role="dialog" ref="formPass" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="back" data-dismiss="modal" aria-label="Back" @click="backPin">
							<span aria-hidden="true">&lt;</span>
						</button>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="backPin">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<form role="form" @submit="checkPass" class="modal-body">
						<div class="form-group">
							<label class="control-label">Password</label>
							<div class="input-group">
								<input id="pwd" class="form-control" v-model="password" value="" type="password" placeholder="Masukkan password" :disabled="loading" />
								<div class="input-group-append">
									<button type="submit" class="btn btn-primary" :disabled="loading">Konfirmasi</button>
								</div>
							</div>
							<div class="h6 font-weight mt-3 text-right" style="color:#FF7203"  @click="loadForgotPass()"><span style="cursor: pointer;">Lupa Kata Sandi?</span></div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-12 card-back">
				<div class="card card-primary card-mx-auto">
					<div class="card-body text-center">
						<div class="alert alert-danger alert-sm" v-if="!pinCheck">{{pinMessage}}</div>
						<div class="h5 font-weight-bold mb-5" v-if="!pinSave">Buat 6 Digit Kode PIN otorisasi Kamu</div>
						<div class="h5 font-weight-bold mb-5" v-if="pinSave">Ulangi Kode PIN otorisasi Kamu untuk Konfirmasi</div>
						<div class="row justify-content-center">
							<div class="col-auto"><div :class="(pinSet.substr(0,1) && pinStar[0] ? 'h4 py-2' : ((pinSet.substr(0,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(0,1) == '' || !pinStar[0] ? '&bull;' : pinSet.substr(0,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(1,1) && pinStar[1] ? 'h4 py-2' : ((pinSet.substr(1,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(1,1) == '' || !pinStar[1] ? '&bull;' : pinSet.substr(1,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(2,1) && pinStar[2] ? 'h4 py-2' : ((pinSet.substr(2,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(2,1) == '' || !pinStar[2] ? '&bull;' : pinSet.substr(2,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(3,1) && pinStar[3] ? 'h4 py-2' : ((pinSet.substr(3,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(3,1) == '' || !pinStar[3] ? '&bull;' : pinSet.substr(3,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(4,1) && pinStar[4] ? 'h4 py-2' : ((pinSet.substr(4,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(4,1) == '' || !pinStar[4] ? '&bull;' : pinSet.substr(4,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(5,1) && pinStar[5] ? 'h4 py-2' : ((pinSet.substr(5,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(5,1) == '' || !pinStar[5] ? '&bull;' : pinSet.substr(5,1)}}</div></div>
						</div>
						<div class="h6 font-weight mt-3">Kode PIN otorisasi digunakan saat kamu masuk ke aplikasi bosCOD</div>
						<div class="m-auto" style="max-width: 400px">
							<div class="row justify-content-center">
								<div class="col-3 btn btn-primary m-1" v-for="index in 9" :key="index" @click="pinKeyPress(index)">
									<span class="h1">{{ index }}</span>
								</div>
								<div class="col-3 btn btn-primary m-1 d-flex justify-content-center align-items-center" @click="pinKeyPress('Clear')">
									<span class="h1 mb-0"><i class="fi fi-rr-arrows-repeat d-flex justify-content-center align-items-center"></i></span>
								</div>
								<div class="col-3 btn btn-primary m-1" @click="pinKeyPress('0')">
									<span class="h1">0</span>
								</div>
								<div class="col-3 btn btn-primary m-1 d-flex justify-content-center align-items-center" @click="pinKeyPress('Backspace')">
									<span class="h1 mb-0"><i class="fi fi-rr-delete d-flex justify-content-center align-items-center"></i></span>
								</div>
							</div>
						</div>
						<div class="overlay d-flex justify-content-center align-items-center" v-if="loadingPin"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
					</div>
				</div>
			</div>
		</div>
		<ForgotPassword :showForgotPass="showForgotPass" />
	</section>
</template>
<script>
import $ from "jquery";
import Swal from "sweetalert2";
import { authFetch } from "@/libs/hxcore";
import ForgotPassword from "@/dialog/ForgotPassword";

export default {
	data() {
		return {
			loading: false,
			password: '',
			openPin: false,
			pinSet: '',
			pinSave: '',
			pinStar: [false, false, false, false, false, false],
			pinCheck: true,
			pinMessage: '',
			loadingPin: false,
			showForgotPass:1,
		};
	},
	created() {
		// window.addEventListener('keyup', this.pinKeyPress);
	},
	beforeDestroy() {
		// window.removeEventListener('keyup', this.pinKeyPress);
	},
	methods: {
		loadForgotPass: function () {
			this.showForgotPass +=1;
		},
		backPin() {
			$(this.$refs.formPass).modal("hide");
			if (!this.issetPin) {
				this.$router.back();
			}
		},
		checkPass: function(ev) {
			this.loading = true;
			if (this.password == '') {
				Swal.fire("Password harus diisi", ``, "error");
			} else if (this.password.length < 8) {
				Swal.fire("Password tidak valid", ``, "error");
			} else {
				var data = "password=" + encodeURIComponent(this.password);
				var urlSubmit = "/shipper/check_password";

				authFetch(urlSubmit, {
					method: "POST",
					body: data,
				}).then((res) => {
					res.json().then((json) =>
					{
						if (json.success) {
							$(this.$refs.formPass).modal("hide");
							// window.addEventListener('keyup', this.pinKeyPress);
						} else {
							Swal.fire({
								title : json.message,
								icon: "error",
							});
						}
						this.loading = false;
					});
					// return res.json();
				});
			}
			ev.preventDefault();
		},
		pinKeyPress(e) {
			var char = parseInt(e);
			if (Number.isInteger(char) && this.pinSet.length < 6) {
				this.pinStar[this.pinSet.length - 1] = false;
				this.pinStar[this.pinSet.length - 0] = true;
				this.pinSet = this.pinSet + char;
				if (this.pinSave.length > 5 && this.pinSet.length > 5) {
					if (this.pinSave == this.pinSet) {
						this.createPin();
					} else {
						this.pinCheck = false;
						this.pinMessage = 'PIN kamu tidak sama!';
						this.pinKeyPress('Clear');
					}
					this.pinStar[this.pinSet.length - 1] = false;
				} else if (this.pinSet.length > 5) {
					this.pinSave = this.pinSet;
					this.pinSet = '';
					this.pinStar[this.pinSet.length - 0] = false;
				}
				// setTimeout(() => { this.pinStar[this.pinSet.length - 1] = false; }, 1000);
			} else if (e == 'Backspace') {
				if (this.pinSet == '' && this.pinSave.length > 5) {
					this.pinSave = '';
				}
				this.pinStar[this.pinSet.length - 0] = false;
				this.pinStar[this.pinSet.length - 1] = true;
				this.pinSet = this.pinSet.substr(0, this.pinSet.length - 1);
				// setTimeout(() => { this.pinStar[this.pinSet.length - 0] = false; }, 1000);
			} else if (e == 'Clear') {
				this.pinSet = '';
				this.pinSave = '';
			}
		},
		createPin: function () {
			this.loadingPin = true;
			let urlSubmit = "/shipper/pin";
			var data = "pinSet=" + this.pinSet + "&pinSave=" + this.pinSave + "&pss=" + this.password;

			authFetch(urlSubmit, 
			{
				method: "POST",
				body: data,
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				res.json().then((json) =>
				{
					if (json.success) {
						Swal.fire({
							title: json.message,
							icon: "success",
						});
						this.openPin = false;
						this.pinSet = '';
						this.pinSave = '';
						this.pinCheck = true;
						this.pinMessage = '';
						this.$router.back();
					} else {
						Swal.fire({
							title : json.message,
							icon: "error",
						});
					}
					this.loadingPin = false;
				});
			});
		},
	},
	components: {
		ForgotPassword
	},
	mounted() {
		const e = this.$refs;
		$(e.formPass).modal("show");
		$('.loading-overlay').removeClass('show');
	},
};
</script>
<style type="text/css">
</style>